import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export interface SessionState {
	signedIn: boolean;
	token: string | null;
	boarded?: boolean;
}

const initialState: SessionState = {
	signedIn: false,
	token: null,
};

const sessionSlice = createSlice({
	name: `${SLICE_BASE_NAME}/session`,
	initialState,
	reducers: {
		signInSuccess(
			state,
			action: PayloadAction<{ token: string; boarded: boolean }>
		) {
			state.signedIn = true;
			state.token = action.payload.token;
			state.boarded = action.payload.boarded;
		},
		signOutSuccess(state) {
			state.signedIn = false;
			state.token = null;
			state.boarded = false;
		},
	},
});

export const { signInSuccess, signOutSuccess } = sessionSlice.actions
export default sessionSlice.reducer
