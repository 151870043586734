import {
	apiResendOTP,
	apiSendOTP,
	apiSignIn,
	apiSignOut,
	apiSignUpEmail,
	apiSignUpPhone,
	apiVerifyCode,
} from "@/services/AuthService";
import {
	setUser,
	signInSuccess,
	signOutSuccess,
	useAppSelector,
	useAppDispatch,
} from "@/store";
import appConfig from "@/configs/app.config";
import { REDIRECT_URL_KEY } from "@/constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import type {
	ResendOTP,
	SignInCredential,
	SignUpEmailCredential,
	SignUpPhoneCredential,
	VerifyCode,
} from "@/@types/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

type Status = "success" | "failed";

function useAuth() {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const functions = getFunctions();

	const query = useQuery();

	const { token, signedIn, boarded } = useAppSelector(
		(state) => state.auth.session
	);

	const signIn = async (
		values: SignInCredential
	): Promise<
		| {
				status: Status;
				message: string;
		  }
		| undefined
	> => {
		try {
			const resp = await apiSignIn(values);
			if (resp.status === "success") {
				const { token } = resp;
				dispatch(signInSuccess({ token, boarded: resp.user.boarded }));
				if (resp.user) {
					dispatch(setUser(resp.user));
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);

				resp.user.boarded
					? navigate(
							redirectUrl
								? redirectUrl
								: appConfig.authenticatedEntryPath
					  )
					: navigate("/onboarding");

				return {
					status: "success",
					message: "",
				};
			}
			// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		} catch (errors: any) {
			return {
				status: "failed",
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const signUpEmail = async (
		values: SignUpEmailCredential,
		adminSignUp?: boolean
	) => {
		try {
			const resp = await apiSignUpEmail(values);

			if (resp.status === "success" && !adminSignUp) {
				navigate("/verify-code", { state: { userId: resp.userId } });
			}

			if (resp.status === "success" && adminSignUp) {
				return {
					status: "success",
					userId: resp.userId,
					message:
						"Account successfully created. Please check for an email with the verification code.",
				};
			}
			// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		} catch (errors: any) {
			return {
				status: "failed",
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const signUpPhone = async (
		values: SignUpPhoneCredential,
		adminSignUp?: boolean
	) => {
		try {
			const resp = await apiSignUpPhone(values);

			if (resp.status === "success" && !adminSignUp)
				navigate("/verify-code", { state: { userId: resp.userId } });

			if (resp.status === "success" && adminSignUp) {
				return {
					status: "success",
					userId: resp.userId,
					message:
						"Account successfully created. Please check for SMS with the verification code.",
				};
			}
		} catch (errors: any) {
			return {
				status: "failed",
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const handleSignOut = () => {
		dispatch(signOutSuccess());
		dispatch(
			setUser({
				avatar: "",
				userName: "",
				email: "",
				authority: [],
			})
		);
		navigate(appConfig.unAuthenticatedEntryPath);
	};

	const signOut = async () => {
		await apiSignOut();
		handleSignOut();
	};

	const verifyCode = async (values: VerifyCode, adminSignUp?: boolean) => {
		const sendEmail = httpsCallable(functions, "sendEmailWithTemplate");

		const response = await apiVerifyCode(values);

		if (response?.status === "success") {
			if (response.user.signUpMethod === "EMAIL") {
				// send welcome email
				await sendEmail({
					to: response.user.email,
					template: "welcome-email",
					subject:
						"Welcome to Sum1 Investments: Unlocking Value for Your Stokvel",
					variables: {
						firstName: response.user.firstName,
						url: import.meta.env.VITE_REACT_APP_BASE_URL,
					},
				});
			}

			if (!adminSignUp) {
				dispatch(
					signInSuccess({
						token: response.token,
						boarded: response.user.boarded,
					})
				);
				if (response.user) dispatch(setUser(response.user));
			} else {
				return {
					status: "success",
					message: "Account successfully verified.",
				};
			}
		}

		return response;
	};

	const resendOTP = async (values: ResendOTP) => {
		const response = await apiResendOTP(values);

		return response;
	};

	const phoneSignIn = async (phone: string) => {
		const response = await apiSendOTP(phone);

		if (response?.status === "success")
			navigate("/verify-code", { state: { userId: response.userId } });

		return response;
	};

	return {
		authenticated: token && signedIn,
		boarded,
		signIn,
		phoneSignIn,
		signUpEmail,
		signUpPhone,
		signOut,
		verifyCode,
		resendOTP,
	};
}

export default useAuth;
