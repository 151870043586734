import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StokvelState = {
	name?: string;
	id?: string;
};

const initialState: StokvelState = {
	name: "",
	id: "",
};

const stokvelSlice = createSlice({
	name: "stokvel",
	initialState,
	reducers: {
		setStokvelState(state, action: PayloadAction<StokvelState>) {
			state.id = action.payload?.id;
			state.name = action.payload?.name;
		},
	},
});

export const { setStokvelState } = stokvelSlice.actions;
export default stokvelSlice.reducer;
